// import { authHeader } from "./auth-header";

export const userService = {
  login,
  logout,
  register,
  // getAll,
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(
    `${process.env.VUE_APP_AXIOS_LINK}auth/admin/login`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.user.data.items) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("userData", JSON.stringify(user.data.items));
      }
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("token");

  localStorage.removeItem("userdata");
  localStorage.removeItem("userid");

  sessionStorage.removeItem("authUser");

  this.$router.push("/");
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  return fetch(
    `${process.env.VUE_APP_AXIOS_LINK}auth/register`,
    requestOptions
  ).then(handleResponse);
}

// function getAll() {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader(),
//   };
//   try {
//     return fetch(`${process.env.VUE_APP_AXIOS_LINK}me`, requestOptions).then(res =>{
//       if(res?.data?.items?.shop){
//         localStorage.setItem('shop_id' , res?.data?.items?.shop?.id )
//       }
//     }).then(
//       handleResponse
//     );
//   } catch (error) {
//     console.log(error);
//   }
// }

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401 || response.status === 403 || response.status == 429) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
