<template>
  <div class="activity-holder">
    <b-card no-body class="card-height-100">
      <!-- <b-card-header>
        <b-card-title title-tag="h5" class="mb-0">{{
          $t("recentActivity")
        }}</b-card-title>
      </b-card-header> -->
      <b-card-body >
        <div class="accordion accordion-border-box" id="genques-accordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="genques-headingOne">
              <b-button variant="none" class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#genques-collapseOne" aria-expanded="true" aria-controls="genques-collapseOne">
                Recent Activity
              </b-button>
            </h2>
            <div id="genques-collapseOne" class="accordion-collapse collapse" aria-labelledby="genques-headingOne"
              data-bs-parent="#genques-accordion" >
              <div class="accordion-body" v-if="activities.length && activityPage">
                <ul class="acitivity-timeline-2 list-unstyled mb-0" >
                  <li v-for="(activity, index) in visibleActivities" :key="index">
                    <h6 class="fs-md">{{ activity.action }}</h6>
                    <p>{{ FormateDate(activity.created_at) }}</p>
                    <p class="mb-0">{{ activity.message }}</p>
                  </li>
                </ul>
                <div class="d-flex justify-content-center mt-5">
                  <b-button variant="outline-primary" v-if="visibleActivityCount < activities.length" @click="loadMoreActivities">Load
                    More</b-button>
                </div>
              </div>
              <h5 class="text-center p-4" v-else>No Activities</h5>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import activities from "@/services/activities"
import { FormateDate } from "@/filters.js";
export default {
  methods: {
    getActivities() {
      let payload = {
        user_id: this.userData.id ? this.userData.id : '',
        loggable_id: this.activityPage == 'User' ? 1 : this.id ? this.id : ''
      }
      activities.getActivities(payload).then(res => {
        let activityName = ''
        res.data.items.data.forEach(element => {
          activityName = element.loggable_type.toString().replace(`App\\Models\\`, ``)
          if (activityName == this.activityPage) {
            this.activities.push(element)
          }
        });
        console.log('activityName' , activityName);
      })
    },
    loadMoreActivities() {
      this.visibleActivityCount += 3;
      if (this.visibleActivityCount > this.activities.length) {
        this.visibleActivityCount = this.activities.length; // Ensure it doesn't exceed total length
      }
    },
    FormateDate
  },
  mounted() {
    this.getActivities()
  },
  data() {
    return {
      id: this.$route.params.id,
      activities: [],
      visibleActivityCount: 4
    }
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
    visibleActivities() {
      return this.activities.slice(0, this.visibleActivityCount);
    }
  },
  props: ['activityPage']
};
</script>

<style lang="scss" scoped></style>