import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import store from "./state/store";
import { vMaska } from "maska";
import "@vueform/multiselect/themes/default.css";

import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import CKEditor from "@ckeditor/ckeditor5-vue";
import BootstrapVueNext from "bootstrap-vue-next";

import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "./assets/scss/app.scss";

import { globalMixin } from "./globalMixin";
// import FacebookPixel from './plugins/facebook-pixel';
// import VueGtag from "vue-gtag";
// import { analyticsMixin } from './mixins/analyticsMixin';
import ActivityComponent from "@/components/activites/Activity.vue";

// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();

// Firebase authentication
// import { initFirebaseBackend } from './authUtils';

// const firebaseConfig = {
//     apiKey: process.env.VUE_APP_APIKEY,
//     authDomain: process.env.VUE_APP_AUTHDOMAIN,
//     databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//     projectId: process.env.VUE_APP_PROJECTId,
//     storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.VUE_APP_MEASUREMENTID
// };

// initFirebaseBackend(firebaseConfig);

import { messaging } from "@/firebaseInit.js";
import { getToken, onMessage } from "firebase/messaging";

// Request user's permission
async function requestUserPermission() {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BGue9-PAXF0yP_nkXACcvTI07TCDZ6DyJOdjmIRGLlyZUhZkyZTV6FjgsDe1tKUrSFiUF-S9ir3R3869qlGQFQ8",
    });
    localStorage.setItem("firebaseToken", token);
  } catch (error) {
    console.log("Failed to get permission or token:", error);
  }
}

requestUserPermission();

// Handle incoming messages
onMessage(messaging, (payload) => {
  console.log("Message received:", payload);
  // Handle the notification (e.g., display a browser notification)

  // For example, display using the native Notification API
  const iconUrl = process.env.VUE_APP_BASE_URL + 'favicon.png';

  if (Notification.permission === "granted") {
    new Notification(payload.notification.title, {
      body: payload.notification.body,
      icon: payload.notification.icon ? payload.notification.icon : iconUrl,
    });
  }
  store.dispatch('notification/getNotifications')

});

// import FiltersPlugin from "./filters.js";
const app = createApp(App);
app
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVueNext)
  .use(i18n)
  // .use(FiltersPlugin)
  .directive("maska", vMaska)
  .use(CKEditor)
  .mixin(globalMixin)
  // .mixin(analyticsMixin)
  .use(vClickOutside)
  .component("ActivityComponent", ActivityComponent);
  // .use(FacebookPixel, { pixelId: 'YOUR_PIXEL_ID' })
  // .use(VueGtag, {
  //   config: { id: "GA_TRACKING_ID" } // Replace "GA_TRACKING_ID" with your Google Analytics tracking ID
  // }, router)

  // router.afterEach((to) => {
  //   app.config.globalProperties.$gtag.pageview({
  //     page_path: to.path,
  //     page_title: to.name,
  //     // Add other details here if needed
  //   });
  // });
  app.mount("#app");
