import store from "@/state/store";
import axios from "axios";

export default [
  // auth
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/activateAccount",
    name: "ActivateAccount",
    component: () => import("../views/account/accountActivate.vue"),
    meta: {
      title: "Account Activate",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      // beforeResolve(routeTo, routeFrom, next) {
      //   // If the user is already logged in
      //   if (store.getters["auth/loggedIn"]) {
      //     // Redirect to the home page instead
      //     next({ name: "default" });
      //   } else {
      //     // Continue to the login page
      //     next();
      //   }
      // },
    },
  },

  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        axios
          .get(`${process.env.VUE_APP_AXIOS_LINK}auth/logout`)
          .then(() => {
            localStorage.clear();
            sessionStorage.clear();
          })
          .finally(() => {
            next();
          });
      },
    },
    component: () => import("../views/account/logout.vue"),
  },

  // Dashboards
  // {
  //   path: "/dashboard/analytics",
  //   name: "analytics",
  //   meta: { title: "Analytics", authRequired: true },
  //   component: () => import("../views/dashboards/analytics/index.vue"),
  // },
  // {
  //   path: "/dashboard/crm",
  //   name: "crm",
  //   meta: { title: "CRM", authRequired: true },
  //   component: () => import("../views/dashboards/crm/index.vue"),
  // },
  {
    path: "/",
    name: "default",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("../views/dashboards/ecommerce/index.vue"),
  },
  // {
  //   path: "/dashboard/learning",
  //   name: "learning",
  //   meta: { title: "Learning", authRequired: true },
  //   component: () => import("../views/dashboards/learning/index.vue"),
  // },
  // {
  //   path: "/dashboard/real-estate",
  //   name: "realEstate",
  //   meta: { title: "Real Estate", authRequired: true },
  //   component: () => import("../views/dashboards/realEstate/index.vue"),
  // },

  // Apps

  // Chat
  {
    path: "/chat",
    name: "chat",
    meta: { title: "Chat", authRequired: true },
    component: () => import("../views/chat/index.vue"),
  },

  // Ecommerce

  {
    path: "/ecommerce/products",
    name: "products",
    meta: { title: "Products", authRequired: true },
    component: () => import("../views/ecommerce/products.vue"),
  },
  {
    path: "/ecommerce/products-grid",
    name: "products-grid",
    meta: { title: "Products Grid", authRequired: true },
    component: () => import("../views/ecommerce/products-grid.vue"),
  },
  {
    path: "/ecommerce/product-details",
    name: "product-details",
    meta: { title: "Product Overview", authRequired: true },
    component: () => import("../views/ecommerce/product-details.vue"),
  },
  {
    path: "/ecommerce/add-product",
    name: "add-product",
    meta: { title: "Add Product", authRequired: true },
    component: () => import("../views/ecommerce/add-product.vue"),
  },
  {
    path: "/ecommerce/orders",
    name: "orders",
    meta: { title: "Orders", authRequired: true },
    component: () => import("../views/ecommerce/orders.vue"),
  },
  {
    path: "/ecommerce/order-overview",
    name: "order-overview",
    meta: { title: "Order Overview", authRequired: true },
    component: () => import("../views/ecommerce/order-overview.vue"),
  },
  {
    path: "/ecommerce/customers",
    name: "customers",
    meta: { title: "Customers", authRequired: true },
    component: () => import("../views/ecommerce/customers.vue"),
  },
  {
    path: "/ecommerce/cart",
    name: "cart",
    meta: { title: "Shop Cart", authRequired: true },
    component: () => import("../views/ecommerce/cart.vue"),
  },
  {
    path: "/ecommerce/checkout",
    name: "checkout",
    meta: { title: "Checkout", authRequired: true },
    component: () => import("../views/ecommerce/checkout.vue"),
  },
  {
    path: "/ecommerce/sellers",
    name: "sellers",
    meta: { title: "Sellers", authRequired: true },
    component: () => import("../views/ecommerce/sellers.vue"),
  },
  {
    path: "/ecommerce/seller-overview",
    name: "seller-overview",
    meta: { title: "Seller Overview", authRequired: true },
    component: () => import("../views/ecommerce/seller-overview.vue"),
  },

  // Invoices
  {
    path: "/invoices/list",
    name: "invoice list",
    meta: { title: "Invoice List", authRequired: true },
    component: () => import("../views/invoices/list.vue"),
  },
  {
    path: "/invoices/overview",
    name: "invoice overview",
    meta: { title: "Invoice Overview", authRequired: true },
    component: () => import("../views/invoices/overview.vue"),
  },
  {
    path: "/invoices/create",
    name: "create invoice",
    meta: { title: "Create Invoice", authRequired: true },
    component: () => import("../views/invoices/create.vue"),
  },

  // Pages
  // Authentication
  {
    path: "/auth/signin",
    name: "signin",
    meta: { title: "Sign In", authRequired: true },
    component: () => import("../views/authentication/signIn.vue"),
  },
  // {
  //   path: "/auth/signup",
  //   name: "signup",
  //   meta: { title: "Sign Up", authRequired: true },
  //   component: () => import("../views/authentication/signup.vue"),
  // },
  {
    path: "/auth/pass-reset",
    name: "pass-reset",
    meta: { title: "Password Reset", authRequired: true },
    component: () => import("../views/authentication/passwordReset.vue"),
  },
  {
    path: "/auth/pass-change",
    name: "pass-change",
    meta: { title: "Password Change", authRequired: true },
    component: () => import("../views/authentication/passwordCreate.vue"),
  },
  {
    path: "/auth/lockscreen",
    name: "lockscreen",
    meta: { title: "Lockscreen", authRequired: true },
    component: () => import("../views/authentication/lockScreen.vue"),
  },
  {
    path: "/auth/logout",
    name: "auth-logout",
    meta: { title: "Logout", authRequired: true },
    component: () => import("../views/authentication/logout.vue"),
  },
  {
    path: "/auth/success-msg",
    name: "success-msg",
    meta: { title: "Success Message", authRequired: true },
    component: () => import("../views/authentication/successMessage.vue"),
  },
  {
    path: "/auth/twostep",
    name: "twostep",
    meta: { title: "Two Step Verification", authRequired: true },
    component: () => import("../views/authentication/twoStepVerification.vue"),
  },
  {
    path: "/auth/404",
    name: "404",
    meta: { title: "404 Error", authRequired: true },
    component: () => import("../views/authentication/Errors/404Error.vue"),
  },
  // {
  //   path: '/:pathMatch(.*)',
  //   name: "404",
  //   meta: { title: "404 Error", authRequired: false },
  //   component: () => import("../views/authentication/Errors/404Error.vue"),
  // },
  {
    path: "/auth/500",
    name: "500",
    meta: { title: "500 Error", authRequired: true },
    component: () => import("../views/authentication/Errors/500.vue"),
  },
  {
    path: "/auth/503",
    name: "503",
    meta: { title: "503 Error", authRequired: true },
    component: () => import("../views/authentication/Errors/503.vue"),
  },
  {
    path: "/auth/offline",
    name: "offline",
    meta: { title: "Offline Page", authRequired: true },
    component: () => import("../views/authentication/Errors/offlinePage.vue"),
  },

  // Pages
  {
    path: "/pages/starter",
    name: "starter",
    meta: { title: "Starter", authRequired: true },
    component: () => import("../views/pages/starter.vue"),
  },
  {
    path: "/pages/profile",
    name: "pages-profile",
    meta: { title: "Profile", authRequired: true },
    component: () => import("../views/pages/profile.vue"),
  },
  {
    path: "/pages/profile-settings",
    name: "profile-settings",
    meta: { title: "Profile Settings", authRequired: true },
    component: () => import("../views/pages/profile-setting.vue"),
  },
  {
    path: "/pages/contacts",
    name: "contacts",
    meta: { title: "Contacts", authRequired: true },
    component: () => import("../views/pages/contacts.vue"),
  },
  {
    path: "/pages/timeline",
    name: "timeline",
    meta: { title: "Timeline", authRequired: true },
    component: () => import("../views/pages/timeline.vue"),
  },
  {
    path: "/pages/faqs",
    name: "faqs",
    meta: { title: "FAQs", authRequired: true },
    component: () => import("../views/pages/faqs.vue"),
  },
  {
    path: "/pages/pricing",
    name: "pricing",
    meta: { title: "Pricing", authRequired: true },
    component: () => import("../views/pages/pricing.vue"),
  },
  {
    path: "/pages/maintenance",
    name: "maintenance",
    meta: { title: "Maintenance", authRequired: true },
    component: () => import("../views/pages/maintenance.vue"),
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { title: "Coming Soon", authRequired: true },
    component: () => import("../views/pages/coming-soon.vue"),
  },
  {
    path: "/pages/privacy-policy",
    name: "privacy-policy",
    meta: { title: "Privacy Policy", authRequired: true },
    component: () => import("../views/pages/privacy-policy.vue"),
  },
  {
    path: "/pages/term-conditions",
    name: "term-conditions",
    meta: { title: "Term Conditions", authRequired: true },
    component: () => import("../views/pages/term-conditions.vue"),
  },

  {
    path: "/categories/categories",
    name: "categories",
    meta: { title: "Categories", authRequired: true },
    component: () => import("../views/categories/categories.vue"),
  },
  {
    path: "/categories/subCategories",
    name: "subCategories",
    meta: { title: "Sub Categories", authRequired: true },
    component: () => import("../views/categories/subCategories.vue"),
  },
  {
    path: "/categories/categories/:id",
    name: "singleCategory",
    meta: { title: "Single Category", authRequired: true },
    component: () => import("../views/categories/singleCategory.vue"),
  },
  {
    path: "/categories/requests",
    name: "requests",
    meta: { title: "Categories Requests", authRequired: true },
    component: () => import("../views/categories/requests.vue"),
  },

  // settings
  {
    path: "/settings/rolesPermissions",
    name: "rolesPermissions",
    meta: { title: "Roles & Permissions", authRequired: true },
    component: () => import("../views/settings/rolesPermissions.vue"),
  },
  {
    path: "/settings/singleRole/:id",
    name: "singleRole",
    meta: { title: "single Roles", authRequired: true },
    component: () => import("../views/settings/singleRole.vue"),
  },
  {
    path: "/settings/faqs",
    name: "faqs",
    meta: { title: "faqs", authRequired: true },
    component: () => import("../views/settings/faqs.vue"),
  },
  {
    path: "/settings/termsConditions",
    name: "termsConditions",
    meta: { title: "Terms and Conditions", authRequired: true },
    component: () => import("../views/settings/termsConditions.vue"),
  },
  {
    path: "/settings/return-policy",
    name: "return-policy",
    meta: { title: "return policy", authRequired: true },
    component: () => import("../views/settings/return-policy.vue"),
  },
  {
    path: "/settings/return-time",
    name: "return-time",
    meta: { title: "return time", authRequired: true },
    component: () => import("../views/settings/return-time.vue"),
  },
  {
    path: "/settings/features",
    name: "features",
    meta: { title: "features", authRequired: true },
    component: () => import("../views/settings/features.vue"),
  },
  {
    path: "/settings/return-policy",
    name: "return-policy",
    meta: { title: "return policy", authRequired: true },
    component: () => import("../views/settings/return-policy.vue"),
  },
  {
    path: "/settings/shipping-policy",
    name: "shipping-policy",
    meta: { title: "Shipping policy", authRequired: true },
    component: () => import("../views/settings/shipping-policy.vue"),
  },
  {
    path: "/settings/about",
    name: "about",
    meta: { title: "About", authRequired: true },
    component: () => import("../views/settings/about.vue"),
  },
  {
    path: "/settings/privacyPolicy",
    name: "privacyPolicy",
    meta: { title: "Privacy Policy", authRequired: true },
    component: () => import("../views/settings/privacyPolicy.vue"),
  },

  // new edit
  {
    path: "/settings/info-images",
    name: "info-images",
    meta: { title: "info-images", authRequired: true },
    component: () => import("../views/settings/info-images.vue"),
  },
  {
    path: "/settings/languages",
    name: "languages",
    meta: { title: "languages", authRequired: true },
    component: () => import("../views/settings/languages.vue"),
  },
  {
    path: "/settings/payment-methods",
    name: "payment-methods",
    meta: { title: "payment-methods", authRequired: true },
    component: () => import("../views/settings/payment-methods.vue"),
  },
  {
    path: "/settings/payment-integrations",
    name: "payment-integrations",
    meta: { title: "payment-integrations", authRequired: true },
    component: () => import("../views/settings/payment-integrations.vue"),
  },
  {
    path: "/settings/translations/translations",
    name: "translations",
    meta: { title: "translations", authRequired: true },
    component: () => import("../views/settings/translations/translations.vue"),
  },
  {
    path: "/settings/translations/editTranslation/:id",
    name: "editTranslation",
    meta: { title: "edit Translation", authRequired: true },
    component: () =>
      import("../views/settings/translations/editTranslation.vue"),
  },
  {
    path: "/settings/mail-integration",
    name: "mail-integration",
    meta: { title: "Mail Integration", authRequired: true },
    component: () => import("../views/settings/mail-integration.vue"),
  },
  {
    path: "/settings/sms-integration",
    name: "sms-integration",
    meta: { title: "sms-integration", authRequired: true },
    component: () => import("../views/settings/sms-integration.vue"),
  },
  {
    path: "/settings/google-analytics",
    name: "google-analytics",
    meta: { title: "google-analytics", authRequired: true },
    component: () => import("../views/settings/google-analytics.vue"),
  },
  {
    path: "/settings/facebook-pixels",
    name: "facebook-pixels",
    meta: { title: "Facebook Pixels", authRequired: true },
    component: () => import("../views/settings/facebook-pixels.vue"),
  },
  {
    path: "/settings/taxes",
    name: "taxes",
    meta: { title: "Taxes", authRequired: true },
    component: () => import("../views/settings/taxes.vue"),
  },
  {
    path: "/settings/currency",
    name: "currency",
    meta: { title: "Currency", authRequired: true },
    component: () => import("../views/settings/currency.vue"),
  },
  {
    path: "/settings/contact-info",
    name: "contact-info",
    meta: { title: "contact-info", authRequired: true },
    component: () => import("../views/settings/contact-info.vue"),
  },
  //users
  // {
  //   path: "/settings/users/users",
  //   name: "users",
  //   meta: { title: "users", authRequired: true },
  //   component: () => import("../views/settings/users/users.vue"),
  // },
  {
    path: "/settings/users/singleuser/:id",
    name: "singleuser",
    meta: { title: "shop", authRequired: true },
    component: () => import("../views/settings/users/singleUser.vue"),
  },
  {
    path: "/settings/users/buyers",
    name: "buyers",
    meta: { title: "buyers", authRequired: true },
    component: () => import("../views/settings/users/buyers.vue"),
  },
  {
    path: "/settings/users/suppliers",
    name: "users-suppliers",
    meta: { title: "Users suppliers", authRequired: true },
    component: () => import("../views/settings/users/suppliers.vue"),
  },
  {
    path: "/settings/users/admins",
    name: "admins",
    meta: { title: "admins", authRequired: true },
    component: () => import("../views/settings/users/admins.vue"),
  },

  // salesOrders
  {
    path: "/salesOrders/salesOrders",
    name: "salesOrders",
    meta: { title: "sales / Orders", authRequired: true },
    component: () => import("../views/salesOrders/salesOrders.vue"),
  },
  {
    path: "/salesOrders/returns",
    name: "returns",
    meta: { title: "sales / Orders", authRequired: true },
    component: () => import("../views/salesOrders/returns.vue"),
  },
  {
    path: "/salesOrders/salesOrders/:id",
    name: "OrderView",
    meta: { title: "sales / Orders", authRequired: true },
    component: () => import("../views/salesOrders/singleSalesOrders.vue"),
  },
  {
    path: "/salesOrders/favourite-products",
    name: "favourite-products",
    meta: { title: "favourite-products", authRequired: true },
    component: () => import("../views/salesOrders/favourite-products.vue"),
  },
  {
    path: "/salesOrders/abandoned-cart",
    name: "abandoned-cart",
    meta: { title: "abandoned-cart", authRequired: true },
    component: () => import("../views/salesOrders/abandoned-cart.vue"),
  },
  {
    path: "/salesOrders/most-viewed",
    name: "most-viewed",
    meta: { title: "most-viewed", authRequired: true },
    component: () => import("../views/salesOrders/most-viewed.vue"),
  },
  {
    path: "/salesOrders/most-order",
    name: "most-order",
    meta: { title: "most-order", authRequired: true },
    component: () => import("../views/salesOrders/most-order.vue"),
  },
  {
    path: "/salesOrders/shipping-rates",
    name: "shipping-rates",
    meta: { title: "shipping-rates", authRequired: true },
    component: () => import("../views/salesOrders/shipping-rates.vue"),
  },
  {
    path: "/salesOrders/pickup-points",
    name: "pickup-points",
    meta: { title: "pickup-points", authRequired: true },
    component: () => import("../views/salesOrders/pickup-points.vue"),
  },
  {
    path: "/reviews/reviews",
    name: "reviews",
    meta: { title: "reviews", authRequired: true },
    component: () => import("../views/reviews/reviews.vue"),
  },

  //suppliers
  {
    path: "/suppliers/suppliers",
    name: "suppliers",
    meta: { title: "Suppliers - Sellers", authRequired: true },
    component: () => import("../views/suppliers/suppliers.vue"),
  },
  {
    path: "/suppliers/singleSupplier/:id",
    name: "singleSupplier",
    meta: { title: "Supplier - Seller", authRequired: true },
    component: () => import("../views/suppliers/singleSupplier.vue"),
  },
  {
    path: "/suppliers/top-suppliers",
    name: "top-suppliers",
    meta: { title: "top-suppliers", authRequired: true },
    component: () => import("../views/suppliers/top-suppliers.vue"),
  },
  //shops
  {
    path: "/shops/shops",
    name: "shops",
    meta: { title: "shops", authRequired: true },
    component: () => import("../views/shops/shops.vue"),
  },
  {
    path: "/shops/singleshop/:id",
    name: "singleShop",
    meta: { title: "shop", authRequired: true },
    component: () => import("../views/shops/singleShop.vue"),
  },

  //brands
  {
    path: "/brands/brands",
    name: "brands",
    meta: { title: "brands", authRequired: true },
    component: () => import("../views/brands/brands.vue"),
  },
  //tags
  {
    path: "/tags/tags",
    name: "tags",
    meta: { title: "tags", authRequired: true },
    component: () => import("../views/tags/tags.vue"),
  },
  //attributes
  {
    path: "/attributes/attributes",
    name: "attributes",
    meta: { title: "attributes", authRequired: true },
    component: () => import("../views/attributes/attributes.vue"),
  },
  {
    path: "/attributes/attributesValues",
    name: "attributesValues",
    meta: { title: "Attributes Values", authRequired: true },
    component: () => import("../views/attributes/attributesValues.vue"),
  },

  //warehouses
  {
    path: "/warehouses/warehouses",
    name: "warehouses",
    meta: { title: "warehouses", authRequired: true },
    component: () => import("../views/warehouses/warehouses.vue"),
  },
  //wallets charge
  {
    path: "/wallets/charges",
    name: "charges",
    meta: { title: "charges", authRequired: true },
    component: () => import("../views/wallets/charges.vue"),
  },
  //wallets redeem
  {
    path: "/wallets/redeems",
    name: "redeems",
    meta: { title: "redeems", authRequired: true },
    component: () => import("../views/wallets/redeems.vue"),
  },
  //messages
  {
    path: "/messages/contactUs",
    name: "contactUs",
    meta: { title: "contactUs", authRequired: true },
    component: () => import("../views/messages/contactUs.vue"),
  },
  {
    path: "/messages/subcribers",
    name: "subcribers",
    meta: { title: "subcribers", authRequired: true },
    component: () => import("../views/messages/subcribers.vue"),
  },

  // locations
  //countries
  {
    path: "/locations/countries",
    name: "countries",
    meta: { title: "countries", authRequired: true },
    component: () => import("../views/locations/countries.vue"),
  },
  // //governorates
  {
    path: "/locations/governorates",
    name: "governorates",
    meta: { title: "governorates", authRequired: true },
    component: () => import("../views/locations/governorates.vue"),
  },
  // //cities
  {
    path: "/locations/cities",
    name: "cities",
    meta: { title: "cities", authRequired: true },
    component: () => import("../views/locations/cities.vue"),
  },

  // products

  {
    path: "/products/products",
    name: "products",
    meta: { title: "Products", authRequired: true },
    component: () => import("../views/products/products.vue"),
  },
  {
    path: "/products/product-details/:id",
    name: "product-details",
    meta: { title: "Product Overview", authRequired: true },
    component: () => import("../views/products/product-details.vue"),
  },
  {
    path: "/products/add-product",
    name: "add-product",
    meta: { title: "Add Product", authRequired: true },
    component: () => import("../views/products/add-product.vue"),
  },
  {
    path: "/products/edit-product/:id",
    name: "edit-product",
    meta: { title: "Edit Product", authRequired: true },
    component: () => import("../views/products/edit-product.vue"),
  },
  {
    path: "/products/productsOptions",
    name: "productsOptions",
    meta: { title: "products Options", authRequired: true },
    component: () => import("../views/products/productsOptions.vue"),
  },
  {
    path: "/products/product-variants",
    name: "product-variants",
    meta: { title: "Product Variants", authRequired: true },
    component: () => import("../views/products/product-variants.vue"),
  },

  //banners
  {
    path: "/banners/banners",
    name: "banners",
    meta: { title: "Banners", authRequired: true },
    component: () => import("../views/banners/banners.vue"),
  },
  //offers
  {
    path: "/offers/offers",
    name: "offers",
    meta: { title: "offers", authRequired: true },
    component: () => import("../views/offers/offers.vue"),
  },
  //special offers

  //buy get offer
  {
    path: "/specialOffers/buy-get-offer",
    name: "buy-get-offer",
    meta: { title: "buy get offer", authRequired: true },
    component: () =>
      import("../views/special-offers/buyGetOffer/buy-get-offers.vue"),
  },
  {
    path: "/specialOffers/singleBuyGetOffer/:id",
    name: "singleBuyGetOffer",
    meta: { title: "show buy get offer", authRequired: true },
    component: () =>
      import("../views/special-offers/buyGetOffer/singleBuyGetOffer.vue"),
  },

  //buy get gift
  {
    path: "/specialOffers/buy-get-gifts",
    name: "buy-get-gift",
    meta: { title: "buy get gift", authRequired: true },
    component: () =>
      import("../views/special-offers/buyGetGift/buy-get-gifts.vue"),
  },
  {
    path: "/specialGifts/singleBuyGetGift/:id",
    name: "singleBuyGetGift",
    meta: { title: "show buy get Gift", authRequired: true },
    component: () =>
      import("../views/special-offers/buyGetGift/singleBuyGetGift.vue"),
  },
  // {
  //   path: "/offers/offers/:id",
  //   name: "singleOffer",
  //   meta: { title: "Single Offer", authRequired: true },
  //   component: () => import("../views/offers/singleOffer.vue"),
  // },

  // baskets
  {
    path: "/baskets/baskets",
    name: "baskets",
    meta: { title: "baskets", authRequired: true },
    component: () => import("../views/baskets/baskets.vue"),
  },
  {
    path: "/baskets/add-basket",
    name: "add-basket",
    meta: { title: "add basket", authRequired: true },
    component: () => import("../views/baskets/add-basket.vue"),
  },
  {
    path: "/baskets/edit-basket/:id",
    name: "edit-basket",
    meta: { title: "Edit basket", authRequired: true },
    component: () => import("../views/baskets/edit-basket.vue"),
  },

  //coupons
  {
    path: "/coupons/coupons",
    name: "coupons",
    meta: { title: "coupons", authRequired: true },
    component: () => import("../views/coupons/coupons.vue"),
  },
  {
    path: "/coupons/singleCoupon/:id",
    name: "singleCoupon",
    meta: { title: "coupon", authRequired: true },
    component: () => import("../views/coupons/singleCoupon.vue"),
  },

  // notifications
  {
    path: "/notifications/notifications",
    name: "notifications",
    meta: { title: "notifications", authRequired: true },
    component: () => import("../views/notifications/notifications.vue"),
  },

  // routing to 404 page if page not found or url is incorrect
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: { title: "404 Error", authRequired: true },
    component: () => import("../views/authentication/Errors/404Error.vue"),
  },

  // Components
];
