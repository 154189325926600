<template>
  <!-- root  -->
  <router-view></router-view>
</template>

<script>
import globalAxios from "./services/global-axios";
// import { useGtag } from 'vue-gtag';
export default {
  name: "App",
  methods: {
    getUserData() {
      globalAxios.get("me").then((res) => {
        if (res?.data?.items) {
          localStorage.setItem("userData", JSON.stringify(res.data.items));
          this.$store.dispatch('auth/setUserNewData')
        }
      });
    },
    submitForm() {
      // Your form submission logic here

      // Track a custom event
      this.$fbq('track', 'Lead');
    }
  },
  // created() {
  //   if (localStorage.getItem("userData")) {
  //     this.getUserData();
  //   }
  // },
  mounted() {
    this.$store.dispatch('notification/getNotifications')
    this.$store.dispatch('auth/setUserNewData')
  }
};
</script>