
import moment from "moment";

export function FormateDate(value) {
  if (!value) return '';
  return moment(String(value)).format("MM/DD/YYYY  -  hh:mm");
}
export function ShoImage(value) {
  if (!value) return '';
  return URL.createObjectURL(value)
}
export function toNumber(value) {
  if (value === undefined || value === null) {
    return 0;
  }
  const num = parseFloat(value);
  return isNaN(num) ? 0 : num;
}


