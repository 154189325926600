import globalAxios from "@/services/global-axios";

export const state = {
  suppliers: [],
  suppliersWithPaginate: [],
  supplier:{}
};

export const mutations = {
  GET_SUPPLIERS(state, data) {
    state.suppliers = data;
  },
  GET_SUPPLIERS_WITH_PAGINATION(state, data) {
    state.suppliersWithPaginate = data;
  },
  GET_SUPPLIER(state, data) {
    state.supplier = data;
  },
};

export const getters = {
  suppliers(state) {
    return state.suppliers;
  },
  suppliersWithPaginate(state) {
    return state.suppliersWithPaginate;
  },
  supplier(state) {
    return state.supplier;
  },
};

export const actions = {
  getSuppliers({ commit }) {
    globalAxios
      .get(`admin/sellers`)
      .then((res) => {
        if (res.status == 200) {
          commit("GET_SUPPLIERS", res.data.items.data);
          commit("GET_SUPPLIERS_WITH_PAGINATION", res.data.items);
        }
      })
      .catch((err) => {
        // const error = Object.values(err)[2].data;
        console.log('err' , err);
      });
  },
  getSuppliersWithoutPaginate({ commit }) {
    globalAxios
      .get(`admin/sellers`,{
        params:{
          paginate:0
        }
      })
      .then((res) => {
        if (res.status == 200) {
          commit("GET_SUPPLIERS", res.data.items.data);
          commit("GET_SUPPLIERS_WITH_PAGINATION", res.data.items);
        }
      })
      .catch((err) => {
        // const error = Object.values(err)[2].data;
        console.log('err' , err);
      });
  },
  getSupplier({ commit } , id) {
    globalAxios
      .get(`admin/sellers/${id}`)
      .then((res) => {
        if (res.status == 200) {
          commit("GET_SUPPLIER", res.data.items);
        }
      })
      .catch((err) => {
        // const error = Object.values(err)[2].data;
        console.log('err' , err);
      });
  },
};
