import axios from "axios";

import router from "@/router/index";
// import store from "../store"
let lang = null;
lang = localStorage.getItem("lang") || "en";

// let userExist = localStorage.getItem("EGate-userInfo");

const getToken = function () {
  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") != "undefined" &&
    localStorage.getItem("token") != undefined
  ) {
    let hasToken = localStorage.getItem("token");
    return hasToken ? `Bearer ${hasToken}` : "";
  }
  return "";
};

export { getToken };

const globalAxios = axios.create({
  baseURL: process.env.VUE_APP_AXIOS_LINK,
  headers: {
    Authorization: getToken(),
    "Accept-Language": lang,
    Accept: "application/json",
  },
});

globalAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status == 403 || error.response.status == 401 || error.response.status == 429) {
      // store.dispatch('Auth/LogOut')
      // router.push(`/auth/login`)
      localStorage.clear();
      sessionStorage.clear();
      router.push(
        {
          path: "/",
        },
        () => {
          router.go(0);
        }
      );
    }
    if (error.response.status == 500 && !localStorage.getItem('userData')) {
      // store.dispatch('Auth/LogOut')
      // router.push(`/auth/login`)
      localStorage.clear();
      sessionStorage.clear();
      router.push(
        {
          path: "/",
        },
        () => {
          router.go(0);
        }
      );
    }

    return Promise.reject(error);
  }
);

export default globalAxios;
