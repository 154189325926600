// globalMixin.js
export const globalMixin = {
  methods: {
    sortTable(column) {
      if (this.currentSort === column) {
        this.currentSortOrder =
          this.currentSortOrder === "asc" ? "desc" : "asc";
      } else {
        this.currentSort = column;
        this.currentSortOrder = "asc";
      }
    },
  },
  computed: {
    imgUrl() {
      return process.env.VUE_APP_BASE_URL
        ? process.env.VUE_APP_BASE_URL
        : null;
    },
    userData(){
      return localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    }
  },
};
