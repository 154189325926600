import globalAxios from "./global-axios";

export default {

  getActivities(payload) {
    return globalAxios.get(`admin/logs`,{
      params:{
        user_id:payload.user_id ? payload.user_id : '',
        loggable_id:payload.loggable_id ? payload.loggable_id : ''
      }
    })
  },
};
