import globalAxios from "@/services/global-axios";

export const state = {
  categories: [],
  categoriesWithPaginate: [],
  category: {},
};

export const mutations = {
  GET_CAREGORIES(state, data) {
    state.categories = data;
  },
  GET_CAREGORIES_WITH_PAGINATION(state, data) {
    state.categoriesWithPaginate = data;
  },
  GET_CAREGORY(state, data) {
    state.category = data;
  },
};

export const getters = {
  categories(state) {
    return state.categories;
  },
  categoriesWithPaginate(state) {
    return state.categoriesWithPaginate;
  },
  category(state) {
    return state.category;
  },
};

export const actions = {
  getCategories({ commit } , page) {
    globalAxios
      .get(`admin/categories`, {
        params: {
          isChiled: true,
          page:page,
          status:1
        },
      })
      .then((res) => {
        if (res.status == 200) {
          commit("GET_CAREGORIES", res.data.items.data);
          commit("GET_CAREGORIES_WITH_PAGINATION", res.data.items);
        }
      })
      .catch((err) => {
        // const error = Object.values(err)[2].data;
        console.log("err", err);
      });
  },
  getAllCategories({ commit } , page) {
    globalAxios
      .get(`admin/categories`, {
        params: {
          isChiled: true,
          page:page
        },
      })
      .then((res) => {
        if (res.status == 200) {
          commit("GET_CAREGORIES", res.data.items.data);
          commit("GET_CAREGORIES_WITH_PAGINATION", res.data.items);
        }
      })
      .catch((err) => {
        // const error = Object.values(err)[2].data;
        console.log("err", err);
      });
  },
  getCategory({ commit }, id) {
    globalAxios
      .get(`admin/categories/${id}`)
      .then((res) => {
        if (res.status == 200) {
          commit("GET_CAREGORY", res.data.items);
        }
      })
      .catch((err) => {
        // const error = Object.values(err)[2].data;
        console.log("err", err);
      });
  },
};
